import React from "react"
import { Link, injectIntl } from "gatsby-plugin-intl"
import { intcomma } from "journalize"
import { Row, Col } from "react-bootstrap"
import BootstrapTable from "react-bootstrap-table-next"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"

import VizContext from "../context/vizcontext"
import { IndexStackedAreaChart } from "../components/stackedareachart"
import TimeGroupDropdown from "../components/timegroupdropdown"
import CategoryDropdown from "../components/categorydropdown"
import OffenseText from "../components/offensetext"
import Legend from "../components/legend"


class IndexDataTable extends React.Component {
  constructor (props) {
    super(props)
    this.props = props

    this.state = {
      timeGroup: this.props.vizState.timeGroup,
      tableData: this.getTableData(this.props),
      expanded: [
        'Battery',
        'Controlled Substances Act',
        'Other Offenses',
        'Criminal Damage and Trespass to Property',
        'Cannabis Control Act'
      ]
    }

    this.defaultSorted = [{
      dataField: 'total_cases',
      order: 'desc'
    }]
  }

  handleTimeGroupChange = (value) => {
    this.props.vizState.setTimeGroup(value)
    this.props.vizState.timeGroup = value
    this.setState(() => ({
      tableData: this.getTableData(this.props)
    }))
  }

  getTableData = (props) => {
    const tableData = []
    props.data.offense_categories.forEach((category) => {
      category.total_cases = props.data.offense_categories.filter(d => d.offense_category === category.offense_category)[0].charges

      if (category.total_cases >= props.chargeCutoff) {
        category.chart = {
          offense_category: category.offense_category,
          total_cases: category.total_cases
        }
        category.name = {
          offense_category: category.offenseCategoryByOffenseCategory.offense_category,
          offense_category_es: category.offenseCategoryByOffenseCategory.offense_category_es
        }
        tableData.push(category)
      }
    })
    return tableData
  }

  render () {
    const intl = this.props.intl

    const columns = [
      {
        dataField: 'name',
        text: intl.formatMessage(
          {
            id: "offense_categories.offense_category",
            defaultMessage: "Offense Category"
          },
        ),
        attrs: (cell, row) => (
          {
            id: row.offenseCategoryByOffenseCategory ? row.offenseCategoryByOffenseCategory.category_slug : ''
          }
        ),
        sort: true,
        formatter: (cell, row, rowIndex, extraData) => {
          return (
            <p className="index-category">
              {intl.formatMessage(
                {
                  id: "offense_categories.name",
                  defaultMessage: cell.offense_category
                },
                {
                  offense_category: cell.offense_category,
                  offense_category_es: cell.offense_category_es
                }
              )}
            </p>
          )
        },
      },
      {
        dataField: 'total_cases',
        text: intl.formatMessage(
          {
            id: "offense_categories.total_cases",
            defaultMessage: "Total Cases"
          },
        ),
        sort: true,
        formatter: (cell, row) => {
          return (
            <p>
              {intl.formatMessage(
                {
                  id: "offense_categories.cases_count",
                  defaultMessage: "{total_cases} cases"
                },
                {
                  total_cases: intcomma(cell),
                }
              )}
            </p>
          )
        }
      },
      {
        dataField: 'chart',
        text: intl.formatMessage(
          {
            id: "offense_categories.cases_over_time",
            defaultMessage: "Cases Over Time"
          },
        ),
        formatter: (cell, row, rowIndex, extraData) => {
          const chartData = extraData.filter(d => d.offense_category === cell.offense_category)
          if (cell.total_cases >= this.props.categoryCutoff) {
            return (
              <div className="index-chart">
                <IndexStackedAreaChart
                  data={chartData}
                  timeGroup={this.props.vizState.timeGroup}
                  intl={intl}
                />
              </div>
            )
          }
        },
        formatExtraData: this.props.data[`top_by_${this.props.vizState.timeGroup}_stacked`]
      }
    ]

    const expandRow = {
      className: 'expander-row',
      renderer: row => {
        const category = row.offenseCategoryByOffenseCategory
        return (
          <OffenseText
            category={category}
            intl={intl}
          >
            <p>
              <Link to={`/${category.category_slug}`}>
                {intl.formatMessage(
                  {
                    id: "offense_categories.read_more_about",
                    defaultMessage: "Read more about"
                  }
                )} {intl.formatMessage(
                  {
                    id: "offense_categories.name",
                    defaultMessage: category.offense_category
                  },
                  {
                    offense_category: category.offense_category,
                    offense_category_es: category.offense_category_es
                  }
                )} →
              </Link>
            </p>
          </OffenseText>
        )
      },
      expanded: this.state.expanded,
      showExpandColumn: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        return <b></b>
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <b>↓</b>
          )
        }
        return (
          <b style={{color: '#6A00F0'}}>→</b>
        )
      }
    }

    return (
      <div>
        <Row
          className="header-dropdowns"
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <TimeGroupDropdown
            vizState={this.props.vizState}
            intl={intl}
          />

          <CategoryDropdown
            offenseCategories={this.props.data.offense_categories}
            intl={intl}
          />

          <Col>
            <Legend
              intl={intl}
            />
          </Col>
        </Row>

        <Row style={{ padding: '1rem 1rem 2rem 1rem' }}>
          <div>
            <p>
              {intl.formatMessage(
                {
                  id: "offense_categories.table_directions",
                  defaultMessage: "Click column titles to sort. Click arrows on left to expand offense descriptions."
                }
              )}
            </p>
          </div>
        </Row>

        <BootstrapTable
          keyField="offense_category"
          data={ this.state.tableData }
          columns={ columns }
          defaultSorted={ this.defaultSorted }
          expandRow={ expandRow }
        />

        <p
          style={{
            fontSize: '1.2rem',
            fontStyle: 'italic',
            paddingTop: '0.5rem'
          }}
        >
          {intl.formatMessage(
            {
              id: "index.cutoff",
              defaultMessage: "Offense categories with fewer than {charge_cutoff} total cases are not shown."
            },
            {
              charge_cutoff: this.props.chargeCutoff
            }
          )}
        </p>
      </div>
    )
  }
}

class IndexUnits extends React.Component {
  render () {
    const { data, intl } = this.props

    return (
      <VizContext.Consumer>
        {vizState => (
          <div>
            <IndexDataTable
              data={data.Courts}
              vizState={vizState}
              intl={intl}
              categoryCutoff={this.props.data.site.siteMetadata.categoryCutoff}
              chargeCutoff={this.props.data.site.siteMetadata.chargeCutoff}
            />
          </div>
        )}
      </VizContext.Consumer>
    )
  }
}

export default injectIntl(IndexUnits)
