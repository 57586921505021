import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/hero"
import IndexUnits from "../components/indexunits"
import { getHTML } from "../components/utils"

const IndexPage = ({ intl, data }) => {
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "title" })} />
      <Hero
        label={intl.formatMessage({ id: "index.hero-label" })}
      >
        <h1 className="hero-headline">
          {intl.formatMessage({ id: "title" })}
        </h1>
        <p style={{maxWidth: '650px', marginTop: '1.5rem', fontWeight: 'bold' }}>
          {intl.formatMessage({
            id: "byline",
            defaultMessage: "By Bea Malsky, Jared Rutecki, Emily Hoerner, Forest Gregg, Trina Reynolds-Tyler, Jean Cochrane, Hannah Cushman Garland, Sam McAlilly and Abby Blachman"
          })}
        </p>
        <br />
        <div
          className='data-in-depth'
          dangerouslySetInnerHTML={{
            __html: getHTML(data),
          }}
        />
      </Hero>
      <div class="page-body">
        <IndexUnits data={data} />
      </div>
    </Layout>
  )
}

export default injectIntl(IndexPage)

export const query = graphql`
  query($language: String!) {
    site {
      siteMetadata {
        categoryCutoff
        chargeCutoff
      }
    }
    current: markdownRemark(
      fields: { page: { pageId: { eq: "intro" }, lang: { eq: $language } } }
    ) {
      html
      fields {
        page {
          path
          lang
        }
      }
    }
    default: markdownRemark(
      fields: { page: { pageId: { eq: "intro" }, lang: { eq: "en" } } }
    ) {
      html
      fields {
        page {
          path
          lang
        }
      }
    }
    Courts {
      total_charges:analysis_total_charges {
        count
      },
      total_cases:analysis_total_cases {
        count
      }
      top_by_month_stacked:analysis_category_counts_by_month_stacked(
        order_by: {case_date: asc},
      ) {
        case_date
        charges_f
        charges_m
        offense_category
      }
      top_by_year_stacked:analysis_category_counts_by_year_stacked(
        order_by: {case_date: asc},
      ) {
        case_date
        charges_f
        charges_m
        offense_category
      },
      offense_categories:analysis_category_counts_all_time(
        order_by: {offense_category: asc},
        where: {charges: {_gte: "750"}}
      ) {
        offense_category
        charges
        offenseCategoryByOffenseCategory {
          category_slug
          offense_category
          offense_category_es
          what_is_it
          what_is_it_es
          data_shows
          data_shows_es
        }
      }
    }
  }
`
